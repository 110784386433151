html[dir="rtl"] {
    .topbar {
        margin-left: 0.75rem !important;
        margin-right: 0 !important;
    }

    .menu-item.headerMenuItem {
        margin-left: 0px !important;
        margin-right: 50px !important;
    }

    .svg-icon.play{
        margin-left:20px;
        margin-right:0px !important;
        svg{
            transform: rotate(180deg);
        }
    }

    .allCoursesGridInfo svg{
        margin-right:0px !important;
        margin-left:5px !important;
    }
    .info_container{
        margin-right: 16px !important;
        .info{
            svg{
                margin-right:0px !important;
                margin-left:5px !important;
            }
        }
    
    }

   
    .pageContainer .coursePreview .subjectsAccordion button{
        text-align: right !important;
    }

    .subjectsAccordion .play-unit:not(.ltr) {
        right: inherit !important;
        left: 0;
    }

    .accordion button.withArrow::before {
        margin-top: 0 !important;
    }

    .pageContainer .coursePreview .coursePreview_Actions .coursePreview_ActionsInfo .coursePreview_ActionsButtons button svg,
    .pageContainer .coursePreview .accordion button.withArrow:not(.ltr):before {
        margin-right:0px !important;
        margin-left:10px !important;
    }

    .div-faqPlus{
        margin-left:0px !important;
        margin-right:15px !important;
    }

    .pageContainer .myCourse_overview .myCourse_courseOverview ul > li:before{
        margin-right:0px !important;
        margin-left:15px !important;
    }

    .pageContainer .myCourse_overview .myCourse_courseOverview ul{
        padding:0 20px !important;
    }

    .changeLanguage{
        margin-left:0px !important;
        margin-right:10px !important;
    }

    .searchBox #spinnerSearch{
        right:auto !important;
        left:0px !important;
    }

    #illusRight :not(.welcomeImg){
        transform: scalex(-1) !important;
    }

    .login-field svg {
        right: auto !important;
        left: 23.59px;
    }

    body .login-field.is-invalid svg, body .login-field.is-valid svg {
        left: 50px;
        right: auto !important;
    }

    body .login-field.is-invalid svg {
        left: 23.59px;
        right: auto !important;
    }

    .coursePreview_ActionsButtons button{
        display: flex;
        // flex-direction: row-reverse;
        align-items: center;
    }
    .svg-icon-1{
        margin-right: 0;
    }
    .was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .form-control:valid, .form-control.is-valid {
        background-position: left calc(0.375em + 0.375rem) center !important;
        padding-right: 1.5rem !important;
    }
    .searchInput{
        padding-right: 30px !important;
    }

    .landing h1 img.logo {
        margin: -27px 0 0 14px !important;
    }

    span.wel-text, .marketIcon:first-child {
        margin: 0 0 13px 14px !important;
    }

    .header .header-logo {
        margin-right: 0 !important;
    }

    .landing h1, .login-gray-message {
       text-align: initial !important;
    }
    .landing p {
        text-align: right !important;
    }
    .myCourse_FAQ {
        .myCourse_FAQItems {
            .card {
                button {
                    text-align: initial !important;
                }
            }
        }
    }   

    @media (min-width: 1025px) {
        .coursePreview_Actions {
            background-position: 4px center !important;
            padding-right: 0 !important;
            padding-left: 4px;
            &:after {
                right: auto !important;
                left: 0;
            }
        }
    }

    @media (max-width: 576px) {
        .header-logo {
            margin-right: 0 !important;
        }

        .landing .h1 .logo {
            margin: -10px 0 0 14px !important;
        }
    }

    .fullWidthChild {
        left: 0 !important;
        right: calc(-50vw + 50%) !important;
    }

    .chart {
        border-left: none !important;
        border-right: 1px solid #3e3e3e !important;
    }
}

button.withArrow.rtl::before {
    margin-right:0px !important;
    margin-left:10px !important;
} 

